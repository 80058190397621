export const carriers = [
    {
      slug: 'la-poste',
      name: 'La Poste',
      logo: '/logo/la-poste.png',
      description: 'Le service postal national français',
      longDescription: 'Découvrez La Poste, le service postal national français offrant une gamme complète de solutions de courrier, colis et services financiers.',
      history: 'Fondée en 1576, La Poste est l\'opérateur postal historique de la France, évoluant pour devenir un acteur majeur dans les services logistiques et financiers.',
      features: ['Livraison à domicile', 'Points relais', 'Boîtes postales'],
      trackingUrlPrefix: 'https://www.laposte.fr/outils/suivre-vos-envois?code=',
    },
    {
      slug: 'chronopost',
      name: 'Chronopost',
      logo: '/logo/chronopost.svg',
      description: 'Spécialiste de la livraison express',
      longDescription: 'Chronopost est le spécialiste de la livraison express, offrant des solutions rapides et fiables pour l\'envoi de colis en France et dans le monde.',
      history: 'Créée en 1985, Chronopost est une filiale du groupe La Poste spécialisée dans la livraison express de colis en France et à l\'international.',
      features: ['Livraison express', 'Livraison le samedi', 'Assurance colis'],
      trackingUrlPrefix: 'https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=',
    },
    {
      slug: 'colissimo',
      name: 'Colissimo',
      logo: '/logo/colissimo.svg',
      description: 'Service de colis de La Poste',
      longDescription: 'Colissimo est le service de livraison de colis de La Poste, proposant des solutions pratiques pour l\'envoi et la réception de colis en France et à l\'international.',
      history: 'Lancé en 1989, Colissimo est le service de colis de La Poste, offrant des solutions de livraison aux particuliers et aux entreprises.',
      features: ['Livraison à domicile', 'Points relais', 'Retour facile'],
      trackingUrlPrefix: 'https://www.laposte.fr/outils/suivre-vos-envois?code=',
    },
    {
      slug: 'dpd',
      name: 'DPD',
      logo: '/logo/dpd.svg',
      description: 'Réseau international de livraison de colis',
      longDescription: 'DPD est un réseau international offrant des services de livraison de colis rapides et fiables à travers le monde.',
      history: 'Fondé en 1976 en Allemagne, DPD (Dynamic Parcel Distribution) est devenu un réseau international de livraison de colis, faisant partie du groupe La Poste depuis 2001.',
      features: ['Livraison internationale', 'Service Predict', 'Pickup parcelshops'],
      trackingUrlPrefix: 'https://trace.dpd.fr/fr/trace/',
    },
    {
      slug: 'ups',
      name: 'UPS',
      logo: '/logo/ups.png',
      description: 'Leader mondial de la logistique',
      longDescription: 'UPS est un leader mondial de la logistique, offrant une large gamme de solutions de transport et de services logistiques.',
      history: 'Fondé en 1907 aux États-Unis, UPS (United Parcel Service) est devenu l\'un des leaders mondiaux de la logistique et de la livraison de colis.',
      features: ['Livraison mondiale', 'Services d\'import-export', 'UPS My Choice'],
      trackingUrlPrefix: 'https://www.ups.com/track?loc=fr_FR&tracknum=',
    },
    {
      slug: 'dhl',
      name: 'DHL',
      logo: '/logo/dhl.svg',
      description: 'Expert en transport international',
      longDescription: 'DHL est un expert en transport international, proposant des services de livraison express, de fret et des solutions e-commerce.',
      history: 'Créé en 1969, DHL est un fournisseur mondial de services logistiques, faisant partie du groupe Deutsche Post DHL.',
      features: ['Express worldwide', 'Transport de fret', 'Solutions e-commerce'],
      trackingUrlPrefix: 'https://www.dhl.com/fr-fr/home/tracking/tracking-express.html?submit=1&tracking-id=',
    },
    {
      slug: 'fedex',
      name: 'FedEx',
      logo: '/logo/fedex.png',
      description: 'Spécialiste de la livraison rapide',
      longDescription: 'FedEx est un spécialiste de la livraison rapide, offrant des services d\'expédition et de logistique à l\'échelle mondiale.',
      history: 'Fondé en 1971, FedEx est une entreprise américaine spécialisée dans le transport international de courrier et de fret, reconnue pour ses services rapides et fiables.',
      features: ['Priority Overnight', 'International Priority', 'FedEx Delivery Manager'],
      trackingUrlPrefix: 'https://www.fedex.com/wtrk/track/?action=track&locale=fr_FR&cntry_code=fr&tracknumbers=',
    },
    {
      slug: 'gls',
      name: 'GLS',
      logo: '/logo/gls.png',
      description: 'Réseau de distribution paneuropéen',
      longDescription: 'GLS est un réseau de distribution paneuropéen, offrant des services de livraison de colis fiables en Europe.',
      history: 'Créé en 1999, GLS (General Logistics Systems) est un fournisseur de services de colis en Europe, filiale de Royal Mail.',
      features: ['Euro Business Parcel', 'Shop Delivery Service', 'Flex Delivery Service'],
      trackingUrlPrefix: 'https://gls-group.eu/FR/fr/suivi-colis?match=',
    },
  ];
  
  export const getCarrierBySlug = (slug) => {
    return carriers.find(carrier => carrier.slug === slug) || null;
  };
  
  export const getCarrierSlugs = () => {
    return carriers.map(carrier => carrier.slug);
  };
  