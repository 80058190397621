import React from 'react';
import { Helmet } from 'react-helmet';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';

export default function MentionsLegales() {
  return (
    <>
      <Helmet>
        <title>Mentions Légales | Colis Facile</title>
        <meta name="description" content="Informations légales concernant Colis Facile, son propriétaire, et l'hébergement du site." />
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Mentions Légales</h1>
        
                <Card className="mb-6">

          <CardHeader>
            <CardTitle>1. Informations sur l'entreprise</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Colis Facile est une société par actions simplifiée (SAS) au capital de 10 000 €</p>
            <p>Siège social : 123 Rue du Colis, 75000 Paris, France</p>
            <p>SIRET : 123 456 789 00001</p>
            <p>RCS Paris B 123 456 789</p>
            <p>N° TVA Intracommunautaire : FR 12 345678900</p>
          </CardContent>
        </Card>

                <Card className="mb-6">

          <CardHeader>
            <CardTitle>2. Directeur de la publication</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Nom du directeur : Jean Dupont</p>
            <p>Adresse email : jean.dupont@colisfacile.com</p>
          </CardContent>
        </Card>

                <Card className="mb-6">

          <CardHeader>
            <CardTitle>3. Hébergement</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Le site Colis Facile est hébergé par :</p>
            <p>OVH SAS</p>
            <p>2 rue Kellermann - 59100 Roubaix - France</p>
          </CardContent>
        </Card>

                <Card className="mb-6">

          <CardHeader>
            <CardTitle>4. Propriété intellectuelle</CardTitle>
          </CardHeader>
          <CardContent>
            <p>L'ensemble du contenu de ce site (textes, images, vidéos) est la propriété exclusive de Colis Facile ou de ses partenaires. Toute reproduction, même partielle, est interdite sans autorisation préalable.</p>
          </CardContent>
        </Card>

                <Card className="mb-6">

          <CardHeader>
            <CardTitle>5. Protection des données personnelles</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Conformément à la loi "Informatique et Libertés" du 6 janvier 1978 modifiée et au Règlement Général sur la Protection des Données (RGPD), vous disposez d'un droit d'accès, de rectification et de suppression des données vous concernant. Pour exercer ce droit, veuillez nous contacter à l'adresse : privacy@colisfacile.com</p>
          </CardContent>
        </Card>

                <Card className="mb-6">

          <CardHeader>
            <CardTitle>6. Cookies</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Notre site utilise des cookies pour améliorer votre expérience de navigation. Vous pouvez configurer votre navigateur pour refuser les cookies ou être averti lors de leur utilisation.</p>
          </CardContent>
        </Card>

                <Card className="mb-6">

          <CardHeader>
            <CardTitle>7. Loi applicable et juridiction</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Les présentes mentions légales sont soumises au droit français. En cas de litige, les tribunaux français seront seuls compétents.</p>
          </CardContent>
        </Card>
      </div>
    </>
  );
}