import React from 'react';
import { Helmet } from 'react-helmet';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';

export default function PolitiqueUtilisation() {
  return (
    <>
      <Helmet>
        <title>Politique d'Utilisation | Colis Facile</title>
        <meta name="description" content="Conditions générales d'utilisation du service Colis Facile pour le suivi de colis et la recherche de points relais." />
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Politique d'Utilisation</h1>
        
        <Card className="mb-6">
          <CardHeader>
            <CardTitle>1. Acceptation des conditions</CardTitle>
          </CardHeader>
          <CardContent>
            <p>En utilisant le service Colis Facile, vous acceptez les présentes conditions d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre service.</p>
          </CardContent>
        </Card>

                <Card className="mb-6">

          <CardHeader>
            <CardTitle>2. Description du service</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Colis Facile est un service de suivi de colis et de recherche de points relais. Nous fournissons des informations sur le statut des colis et l'emplacement des points relais en nous basant sur les données fournies par les transporteurs.</p>
          </CardContent>
        </Card>

                <Card className="mb-6">

          <CardHeader>
            <CardTitle>3. Utilisation du service</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Vous vous engagez à utiliser notre service de manière légale et conformément à ces conditions. Vous ne devez pas :</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Utiliser le service d'une manière qui pourrait endommager, désactiver ou surcharger nos systèmes</li>
              <li>Tenter d'accéder à des zones restreintes de notre site</li>
              <li>Utiliser le service pour des activités illégales ou frauduleuses</li>
            </ul>
          </CardContent>
        </Card>

                <Card className="mb-6">

          <CardHeader>
            <CardTitle>4. Exactitude des informations</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Bien que nous nous efforcions de fournir des informations précises, nous ne pouvons garantir l'exactitude, l'exhaustivité ou la pertinence des informations fournies par notre service. Les informations de suivi de colis sont fournies par les transporteurs et nous ne sommes pas responsables des erreurs ou retards dans ces informations.</p>
          </CardContent>
        </Card>

                <Card className="mb-6">

          <CardHeader>
            <CardTitle>5. Propriété intellectuelle</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Tout le contenu présent sur notre site, y compris les textes, graphiques, logos et images, est la propriété de Colis Facile ou de nos partenaires et est protégé par les lois sur la propriété intellectuelle.</p>
          </CardContent>
        </Card>

                <Card className="mb-6">

          <CardHeader>
            <CardTitle>6. Limitation de responsabilité</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Colis Facile ne sera pas responsable des dommages directs, indirects, accessoires ou consécutifs résultant de l'utilisation ou de l'impossibilité d'utiliser notre service.</p>
          </CardContent>
        </Card>

                <Card className="mb-6">

          <CardHeader>
            <CardTitle>7. Modifications du service</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Nous nous réservons le droit de modifier, suspendre ou interrompre tout ou partie de notre service à tout moment, avec ou sans préavis.</p>
          </CardContent>
        </Card>

                <Card className="mb-6">

          <CardHeader>
            <CardTitle>8. Loi applicable</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Ces conditions d'utilisation sont régies par le droit français. Tout litige relatif à l'interprétation ou à l'exécution de ces conditions sera de la compétence exclusive des tribunaux français.</p>
          </CardContent>
        </Card>
      </div>
    </>
  );
}