import React from 'react';
import { Helmet } from 'react-helmet';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Politique de Confidentialité | Colis Facile</title>
        <meta name="description" content="Découvrez comment Colis Facile protège vos données personnelles et respecte votre vie privée." />
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Politique de Confidentialité</h1>
        <p className="text-lg mb-6">Dernière mise à jour : {new Date().toLocaleDateString('fr-FR')}</p>
        
        <Card className="mb-6">
          <CardHeader>
            <CardTitle>1. Introduction</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Chez Colis Facile, nous nous engageons à protéger votre vie privée et vos données personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos informations lorsque vous utilisez notre service de suivi de colis et de recherche de points relais.</p>
          </CardContent>
        </Card>

        <Card className="mb-6">
          <CardHeader>
            <CardTitle>2. Informations que nous collectons</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Nous collectons les types d'informations suivants :</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Informations que vous nous fournissez : numéros de suivi de colis, adresses pour la recherche de points relais.</li>
              <li>Informations collectées automatiquement : adresse IP, type de navigateur, pages visitées sur notre site.</li>
              <li>Cookies et technologies similaires : pour améliorer votre expérience sur notre site.</li>
            </ul>
          </CardContent>
        </Card>

        <Card className="mb-6">
          <CardHeader>
            <CardTitle>3. Comment nous utilisons vos informations</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Nous utilisons vos informations pour :</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Fournir et améliorer nos services de suivi de colis et de recherche de points relais.</li>
              <li>Personnaliser votre expérience utilisateur.</li>
              <li>Communiquer avec vous concernant nos services.</li>
              <li>Analyser l'utilisation de notre site pour l'améliorer.</li>
            </ul>
          </CardContent>
        </Card>

        <Card className="mb-6">
          <CardHeader>
            <CardTitle>4. Protection des données</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Nous mettons en place des mesures de sécurité appropriées pour protéger vos informations contre tout accès non autorisé, altération, divulgation ou destruction. Cela inclut le chiffrement des données, des pare-feu et des contrôles d'accès stricts à nos systèmes.</p>
          </CardContent>
        </Card>

        <Card className="mb-6">
          <CardHeader>
            <CardTitle>5. Vos droits</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Conformément au Règlement Général sur la Protection des Données (RGPD), vous avez le droit de :</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Accéder à vos données personnelles</li>
              <li>Rectifier vos données personnelles</li>
              <li>Effacer vos données personnelles</li>
              <li>Limiter le traitement de vos données personnelles</li>
              <li>Vous opposer au traitement de vos données personnelles</li>
              <li>Demander la portabilité de vos données</li>
            </ul>
          </CardContent>
        </Card>

        <Card className="mb-6">
          <CardHeader>
            <CardTitle>6. Modifications de cette politique</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Nous vous encourageons à consulter régulièrement cette page pour vous tenir informé des changements. La date de la dernière mise à jour sera toujours indiquée en haut de cette page.</p>
          </CardContent>
        </Card>

        <Card className="mb-6">
          <CardHeader>
            <CardTitle>7. Nous contacter</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Si vous avez des questions concernant cette politique de confidentialité ou la manière dont nous traitons vos données personnelles, veuillez nous contacter à :</p>
            <p className="mt-2">
              Email : privacy@colisfacile.com<br />
              Adresse : 123 Rue de la Confidentialité, 75000 Paris, France
            </p>
          </CardContent>
        </Card>
      </div>
    </>
  );
}