import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from "../components/ui/button";

function Header() {
  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <div className="flex-shrink-0">
            <Link to="/" className="text-2xl font-bold text-primary">
              Colis Facile
            </Link>
          </div>
          <nav className="hidden md:flex space-x-4">
            <Link to="/" className="text-gray-500 hover:text-gray-900">Accueil</Link>
            <Link to="/suivi" className="text-gray-500 hover:text-gray-900">Suivi de colis</Link>
            <Link to="#" className="text-gray-500 hover:text-gray-900">Point relais</Link>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
