import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Package, Home, Search, HelpCircle, MapPin, Truck, ArrowRight } from 'lucide-react';

export default function Error404() {
  return (
    <>
      <Helmet>
        <title>Page non trouvée | Colis Facile</title>
        <meta name="description" content="Oups ! La page que vous recherchez semble s'être égarée. Retrouvez votre chemin sur Colis Facile." />
      </Helmet>

      <div className="container mx-auto px-4 py-16 text-center">
        <div className="max-w-2xl mx-auto">
          <h1 className="text-4xl font-bold mb-8">404 - Colis égaré !</h1>
          <p className="text-xl mb-8">
            Oups ! Il semble que cette page se soit perdue en cours de livraison.
            <br />
            Ne vous inquiétez pas, nous allons vous aider à retrouver votre chemin !
          </p>

          <div className="grid gap-4 sm:grid-cols-2 max-w-md mx-auto mb-12">
            <Button asChild size="lg" className="w-full">
              <Link to="/">
                <Home className="mr-2 h-5 w-5" />
                Retour à l'accueil
              </Link>
            </Button>
            <Button asChild variant="outline" size="lg" className="w-full">
              <Link to="/contact">
                <HelpCircle className="mr-2 h-5 w-5" />
                Contactez-nous
              </Link>
            </Button>
          </div>

          <Card>
            <CardHeader>
              <CardTitle className="text-2xl">Vous cherchiez peut-être...</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
              <Link to="/suivi" className="group">
                <Card className="h-full transition-colors hover:border-primary">
                  <CardContent className="flex flex-col items-center justify-center p-6">
                    <Search className="h-12 w-12 mb-4 text-muted-foreground group-hover:text-primary transition-colors" />
                    <p className="text-center font-medium">Suivre un colis</p>
                    <ArrowRight className="mt-2 h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                  </CardContent>
                </Card>
              </Link>
              <Link to="#" className="group">
                <Card className="h-full transition-colors hover:border-primary">
                  <CardContent className="flex flex-col items-center justify-center p-6">
                    <MapPin className="h-12 w-12 mb-4 text-muted-foreground group-hover:text-primary transition-colors" />
                    <p className="text-center font-medium">Trouver un point relais</p>
                    <ArrowRight className="mt-2 h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                  </CardContent>
                </Card>
              </Link>
              <Link to="/" className="group">
                <Card className="h-full transition-colors hover:border-primary">
                  <CardContent className="flex flex-col items-center justify-center p-6">
                    <HelpCircle className="h-12 w-12 mb-4 text-muted-foreground group-hover:text-primary transition-colors" />
                    <p className="text-center font-medium">En savoir plus sur Colis Facile</p>
                    <ArrowRight className="mt-2 h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                  </CardContent>
                </Card>
              </Link>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}