import React, { createContext, useContext, useState } from 'react';
import { ChevronDown } from 'lucide-react';

const AccordionContext = createContext(null);

export function Accordion({ children, type = "single", collapsible = false, className = "" }) {
  const [openItems, setOpenItems] = useState(new Set());

  const toggleItem = (value) => {
    setOpenItems((prevOpenItems) => {
      const newOpenItems = new Set(prevOpenItems);
      if (type === "single") {
        newOpenItems.clear();
      }
      if (newOpenItems.has(value)) {
        if (collapsible || newOpenItems.size > 1) {
          newOpenItems.delete(value);
        }
      } else {
        newOpenItems.add(value);
      }
      return newOpenItems;
    });
  };

  return (
    <AccordionContext.Provider value={{ openItems, toggleItem }}>
      <div className={`space-y-1 ${className}`}>{children}</div>
    </AccordionContext.Provider>
  );
}

export function AccordionItem({ children, value }) {
  const { openItems } = useContext(AccordionContext);
  const isOpen = openItems.has(value);

  return (
    <div className="border-b">
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { isOpen, value })
      )}
    </div>
  );
}

export function AccordionTrigger({ children, isOpen, value }) {
  const { toggleItem } = useContext(AccordionContext);

  return (
    <button
      className="flex w-full items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180"
      onClick={() => toggleItem(value)}
      data-state={isOpen ? "open" : "closed"}
    >
      {children}
      <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
    </button>
  );
}

export function AccordionContent({ children, isOpen }) {
  if (!isOpen) return null;

  return (
    <div className="overflow-hidden text-sm transition-all">
      <div className="pb-4 pt-0">{children}</div>
    </div>
  );
}