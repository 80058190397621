import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../components/ui/card";
import { Package, Truck, Clock, MapPin, Info } from 'lucide-react';
import { getCarrierBySlug } from '../data/carrierData';

export default function SuiviTransporteur() {
  const { carrier: carrierSlug } = useParams();
  const [trackingNumber, setTrackingNumber] = useState('');
  const carrierInfo = getCarrierBySlug(carrierSlug);

  if (!carrierInfo) {
    return <div>Transporteur non trouvé</div>;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    window.open(`${carrierInfo.trackingUrlPrefix}${trackingNumber}`, '_blank');
  };

  return (
    <>
      <Helmet>
        <title>Suivi de colis {carrierInfo.name} | Colis Facile</title>
        <meta name="description" content={`${carrierInfo.longDescription} Utilisez notre outil de suivi pour localiser vos colis ${carrierInfo.name} facilement.`} />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="text-center mb-8">
          <img src={carrierInfo.logo} alt={`Logo ${carrierInfo.name}`} className="h-16 mx-auto mb-4 rounded-lg" />
          <h1 className="text-3xl font-bold mb-2">Suivi de colis {carrierInfo.name}</h1>
          <p className="text-xl text-gray-600">{carrierInfo.longDescription}</p>
        </div>

        <Card className="mb-8">
          <CardHeader>
            <CardTitle className="text-2xl">Suivre votre colis {carrierInfo.name}</CardTitle>
            <CardDescription>Entrez votre numéro de suivi pour obtenir les dernières informations sur votre colis</CardDescription>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-4">
              <Input
                type="text"
                placeholder="Numéro de suivi"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
                required
                className="text-lg p-6"
              />
              <Button type="submit" className="w-full text-lg p-6">
                Suivre mon colis {carrierInfo.name}
              </Button>
            </form>
          </CardContent>
        </Card>

        <div className="grid md:grid-cols-2 gap-6 mb-8">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Truck className="mr-2" />
                Services de livraison
              </CardTitle>
            </CardHeader>
            <CardContent>
              <ul className="list-disc pl-5">
                {carrierInfo.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Info className="mr-2" />
                À propos de {carrierInfo.name}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p>{carrierInfo.history}</p>
            </CardContent>
          </Card>
        </div>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <MapPin className="mr-2" />
              Trouver un point relais {carrierInfo.name}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="mb-4">Besoin de déposer ou récupérer un colis ? Trouvez le point relais {carrierInfo.name} le plus proche de chez vous.</p>
            <Button asChild>
              <Link to={`#${carrierInfo.slug}`}>Rechercher un point relais</Link>
            </Button>
          </CardContent>
        </Card>
      </div>
    </>
  );
}