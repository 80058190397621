import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from "../components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../components/ui/accordion";
import { Package, Search, Truck, Clock, Globe, HelpCircle } from 'lucide-react';
import { carriers, getCarrierBySlug } from '../data/carrierData';

export default function SuiviColis() {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [carrier, setCarrier] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedCarrier = getCarrierBySlug(carrier);
    if (selectedCarrier && trackingNumber) {
      window.open(`${selectedCarrier.trackingUrlPrefix}${trackingNumber}`, '_blank');
    } else {
      alert("Veuillez sélectionner un transporteur et entrer un numéro de suivi.");
    }
  };

  const faqItems = [
    {
      question: "Comment suivre mon colis ?",
      answer: "Pour suivre votre colis, entrez simplement le numéro de suivi fourni par le transporteur dans le champ prévu à cet effet, sélectionnez le transporteur correspondant, puis cliquez sur 'Suivre le colis'. Vous serez redirigé vers la page de suivi du transporteur avec les informations les plus récentes sur votre colis."
    },
    {
      question: "Quels transporteurs sont pris en charge ?",
      answer: "Nous prenons en charge le suivi des colis pour de nombreux transporteurs, notamment La Poste, Chronopost, Colissimo, DPD, UPS, DHL, FedEx, et GLS. Vous pouvez voir la liste complète des transporteurs pris en charge dans la section 'Transporteurs disponibles' ci-dessous."
    },
    {
      question: "Que faire si mon numéro de suivi ne fonctionne pas ?",
      answer: "Si votre numéro de suivi ne fonctionne pas, vérifiez d'abord que vous l'avez correctement saisi et que vous avez sélectionné le bon transporteur. Si le problème persiste, il est possible que le colis n'ait pas encore été pris en charge par le système de suivi du transporteur. Dans ce cas, attendez quelques heures et réessayez. Si le problème persiste après 24 heures, contactez directement le transporteur ou l'expéditeur."
    },
    {
      question: "Les informations de suivi sont-elles en temps réel ?",
      answer: "Nous nous efforçons de fournir les informations les plus à jour possible. Cependant, la fréquence des mises à jour dépend de chaque transporteur. En général, les informations sont actualisées plusieurs fois par jour, mais il peut y avoir un léger décalage entre le statut réel du colis et les informations affichées."
    },
    {
      question: "Puis-je suivre des colis internationaux ?",
      answer: "Oui, vous pouvez suivre des colis internationaux avec notre service, à condition qu'ils soient gérés par l'un des transporteurs que nous prenons en charge. Notez que pour certains trajets internationaux, le suivi peut être moins détaillé que pour les livraisons nationales."
    }
  ];

  return (
    <>
      <Helmet>
        <title>Suivi de Colis en Ligne | Colis Facile</title>
        <meta name="description" content="Suivez facilement vos colis en ligne avec Colis Facile. Service gratuit pour La Poste, Chronopost, DHL, UPS et plus. Obtenez des mises à jour en temps réel sur l'état de votre livraison." />
        <meta name="keywords" content="suivi colis, tracking, La Poste, Chronopost, DHL, UPS, FedEx, livraison, e-commerce" />
        <link rel="canonical" href="https://www.colisfacile.com/suivi-colis" />
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-center mb-8">Suivi de Colis en Ligne</h1>
        
        <Card className="max-w-2xl mx-auto mb-12">
          <CardHeader>
            <CardTitle className="text-2xl flex items-center">
              <Package className="mr-2" />
              Suivre votre colis
            </CardTitle>
            <CardDescription>
              Entrez votre numéro de suivi et sélectionnez le transporteur pour obtenir les dernières informations sur votre colis.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-4">
              <Input
                type="text"
                placeholder="Numéro de suivi"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
                required
                className="text-lg"
              />
              <Select onValueChange={setCarrier} required>
                <SelectTrigger className="w-full text-lg">
                  <SelectValue placeholder="Choisir un transporteur" />
                </SelectTrigger>
                <SelectContent>
                  {carriers.map((c) => (
                    <SelectItem key={c.slug} value={c.slug}>{c.name}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Button type="submit" className="w-full text-lg">
                <Search className="mr-2" />
                Suivre le colis
              </Button>
            </form>
          </CardContent>
        </Card>

        <div className="grid md:grid-cols-3 gap-6  mx-auto mb-12">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Truck className="mr-2" />
                Suivi multi-transporteurs
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p>Suivez vos colis auprès de tous les grands transporteurs en France et à l'international, le tout depuis une seule plateforme.</p>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Clock className="mr-2" />
                Mises à jour en temps réel
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p>Recevez des informations actualisées sur l'état de votre colis, de l'expédition à la livraison finale.</p>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Globe className="mr-2" />
                Couverture internationale
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p>Que votre colis soit en France ou à l'étranger, nous vous fournissons les informations de suivi dont vous avez besoin.</p>
            </CardContent>
          </Card>
        </div>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-6">Transporteurs disponibles</h2>
          <div className="grid md:grid-cols-4 gap-4">
            {carriers.map((carrier) => (
              <Card key={carrier.slug}>
                <CardHeader>
                  <img src={carrier.logo} alt={`Logo ${carrier.name}`} className="h-12 mb-2 w-12 rounded-lg" />
                  <CardTitle>{carrier.name}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-sm">{carrier.description}</p>
                </CardContent>
                <CardFooter>
                  <Button asChild variant="link">
                    <Link to={`/suivi/${carrier.slug}`}>En savoir plus</Link>
                  </Button>
                </CardFooter>
              </Card>
            ))}
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-6 flex items-center">
            <HelpCircle className="mr-2" />
            Foire Aux Questions
          </h2>
          <Accordion type="single" collapsible className="w-full">
            {faqItems.map((item, index) => (
              <AccordionItem value={`item-${index}`} key={index}>
                <AccordionTrigger>{item.question}</AccordionTrigger>
                <AccordionContent>{item.answer}</AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </section>
      </div>
    </>
  );
}